// import "bootstrap-select";
// import "bootstrap-select/dist/css/bootstrap-select.css";
// require('bootstrap-select/js/bootstrap-select.js');
// $.fn.selectpicker.Constructor.BootstrapVersion = '5';
document.addEventListener("turbolinks:load", function() {
var body = $('body');
var html = $('html');

function dzSettings({typography, version, layout, navheaderBg, headerBg, sidebarStyle, sidebarBg, sidebarPosition, headerPosition, containerLayout, direction, primary, secondary}) {
	this.typography = typography || "poppins";
	this.version = version || "light";
	this.layout = layout || "vertical";
	this.navheaderBg = navheaderBg || "color_1";
	this.headerBg = headerBg || "color_1";
	this.sidebarStyle = sidebarStyle || "full";
	this.sidebarBg = sidebarBg || "color_1";
	this.sidebarPosition = sidebarPosition || "static";
	this.headerPosition = headerPosition || "static";
	this.containerLayout = containerLayout || "wide";
	this.direction = direction || "ltr";
this.primary = primary || "color_1";
this.secondary = secondary || "color_1";

	this.manageTypography();
	this.manageVersion();
	this.manageLayout();
	this.manageNavHeaderBg();
	this.manageHeaderBg();
	this.manageSidebarStyle();
	this.manageSidebarBg();
	this.manageSidebarPosition();
	this.manageHeaderPosition();
	this.manageContainerLayout();
	this.manageRtlLayout();
	this.manageResponsiveSidebar();
this.managePrimaryColor();
this.manageSecondaryColor();
}
dzSettings.prototype.manageVersion = function() {
	switch(this.version) {
			case "light": 
					body.attr("data-theme-version", "light");
					break;
			case "dark": 
					body.attr("data-theme-version", "dark");
					break;
			case "transparent": 
					body.attr("data-theme-version", "transparent");
					break;
			default: 
					body.attr("data-theme-version", "light");
	}
}
dzSettings.prototype.manageTypography = function() {
	switch(this.typography) {
	case "cairo": 
					body.attr("data-typography", "cairo");
					break;
			case "poppins": 
					body.attr("data-typography", "poppins");
					break;
			case "roboto": 
					body.attr("data-typography", "roboto");
					break;
			case "opensans": 
					body.attr("data-typography", "opensans");
					break;
	case "helveticaneue": 
					body.attr("data-typography", "helveticaneue");
					break;
			default: 
					body.attr("data-typography", "poppins");
	}
}
dzSettings.prototype.manageLayout = function() {
	switch(this.layout) {
			case "horizontal": 
					this.sidebarStyle === "overlay" ? body.attr("data-sidebar-style", "full") : body.attr("data-sidebar-style", `${this.sidebarStyle}`);
					body.attr("data-layout", "horizontal");
					break;
			case "vertical": 
					body.attr("data-layout", "vertical");
					break;
			default:
					body.attr("data-layout", "vertical");
	}
}
dzSettings.prototype.manageNavHeaderBg = function() {
	switch(this.navheaderBg) {
			case "color_1": 
					body.attr("data-nav-headerbg", "color_1");
					break;
			case "color_2": 
					body.attr("data-nav-headerbg", "color_2");
					break;
			case "color_3": 
					body.attr("data-nav-headerbg", "color_3");
					break;
			case "color_4": 
					body.attr("data-nav-headerbg", "color_4");
					break;
			case "color_5": 
					body.attr("data-nav-headerbg", "color_5");
					break;
			case "color_6": 
					body.attr("data-nav-headerbg", "color_6");
					break;
			case "color_7": 
					body.attr("data-nav-headerbg", "color_7");
					break;
			case "color_8": 
					body.attr("data-nav-headerbg", "color_8");
					break;
			case "color_9": 
					body.attr("data-nav-headerbg", "color_9");
					break;
			case "color_10": 
					body.attr("data-nav-headerbg", "color_10");
					break;
	case "color_11": 
					body.attr("data-nav-headerbg", "color_11");
					break;
	case "color_12": 
					body.attr("data-nav-headerbg", "color_12");
					break;
	case "color_13": 
					body.attr("data-nav-headerbg", "color_13");
					break;
	case "color_14": 
					body.attr("data-nav-headerbg", "color_14");
					break;
	case "color_15": 
					body.attr("data-nav-headerbg", "color_15");
					break;		
			case "image_1": 
					body.attr("data-nav-headerbg", "image_1");
					break;
			case "image_2": 
					body.attr("data-nav-headerbg", "image_2");
					break;
			case "image_3": 
					body.attr("data-nav-headerbg", "image_3");
					break;
			default:
					body.attr("data-nav-headerbg", "color_1");
	}
}
dzSettings.prototype.manageHeaderBg = function() {
	switch(this.headerBg) {
			case "color_1": 
					body.attr("data-headerbg", "color_1");
					break;
			case "color_2": 
					body.attr("data-headerbg", "color_2");
					break;
			case "color_3": 
					body.attr("data-headerbg", "color_3");
					break;
			case "color_4": 
					body.attr("data-headerbg", "color_4");
					break;
			case "color_5": 
					body.attr("data-headerbg", "color_5");
					break;
			case "color_6": 
					body.attr("data-headerbg", "color_6");
					break;
			case "color_7": 
					body.attr("data-headerbg", "color_7");
					break;
			case "color_8": 
					body.attr("data-headerbg", "color_8");
					break;
			case "color_9": 
					body.attr("data-headerbg", "color_9");
					break;
			case "color_10": 
					body.attr("data-headerbg", "color_10");
					break;
	case "color_11": 
					body.attr("data-headerbg", "color_11");
					break;
	case "color_12": 
					body.attr("data-headerbg", "color_12");
					break;
	case "color_13": 
					body.attr("data-headerbg", "color_13");
					break;
	case "color_14": 
					body.attr("data-headerbg", "color_14");
					break;
	case "color_15": 
					body.attr("data-headerbg", "color_15");
					break;		
			case "transparent": 
					body.attr("data-headerbg", "transparent");
					break;
			case "gradient_1": 
					body.attr("data-headerbg", "gradient_1");
					break;
			case "gradient_2": 
					body.attr("data-headerbg", "gradient_2");
					break;
			case "gradient_3": 
					body.attr("data-headerbg", "gradient_3");
					break;
			default:
					body.attr("data-headerbg", "color_1");
	}
}
dzSettings.prototype.manageSidebarStyle = function() {


	switch(this.sidebarStyle) {
			case "full":
					body.attr("data-sidebar-style", "full");
					break;
			case "mini":
					body.attr("data-sidebar-style", "mini");
					break;
			case "compact":
					body.attr("data-sidebar-style", "compact");
					break;
			case "modern":
					body.attr("data-sidebar-style", "modern");
					break;
			case "icon-hover":
					body.attr("data-sidebar-style", "icon-hover");
		
		$("body").on("mouseenter", ".deznav", function(){
			$('#main-wrapper').addClass('iconhover-toggle');
		}).on("mouseleave", ".deznav", function(){
			$('#main-wrapper').removeClass('iconhover-toggle');
		});
		
		/* $('.deznav').on("mouseenter",function() {
							$('#main-wrapper').addClass('iconhover-toggle');
					}, function() {
							$('#main-wrapper').removeClass('iconhover-toggle');
					});  */
		/* var currentBodyLayout = currentSidebarStyle = '';
		
		$('.deznav').hover(function() {
			
			 if(body.attr('data-sidebar-style') === 'icon-hover') { $('#main-wrapper').addClass('iconhover-toggle'); }
			
			currentBodyLayout = jQuery('body').attr('data-layout');
			currentSidebarStyle = jQuery('body').attr('data-sidebar-style');
			
							if(currentBodyLayout === 'vertical' && currentSidebarStyle === 'icon-hover'){ $('.metismenu .mm-active ul').addClass('mm-show'); }
					
		}, function() {
			
			 if(body.attr('data-sidebar-style') === 'icon-hover') { $('#main-wrapper').removeClass('iconhover-toggle'); }
			
			currentBodyLayout = jQuery('body').attr('data-layout');
			currentSidebarStyle = jQuery('body').attr('data-sidebar-style');
							
			if(currentBodyLayout === 'vertical' && currentSidebarStyle === 'icon-hover'){ $('.metismenu .mm-active ul').removeClass('mm-show'); }
					}); */            
					break;
			case "overlay":
					this.layout === "horizontal" ? body.attr("data-sidebar-style", "full") : body.attr("data-sidebar-style", "overlay");
					break;
			default:
					body.attr("data-sidebar-style", "full");
	}
}
dzSettings.prototype.manageSidebarBg = function() {
	switch(this.sidebarBg) {
			case "color_1": 
					body.attr("data-sidebarbg", "color_1");
					break;
			case "color_2": 
					body.attr("data-sidebarbg", "color_2");
					break;
			case "color_3": 
					body.attr("data-sidebarbg", "color_3");
					break;
			case "color_4": 
					body.attr("data-sidebarbg", "color_4");
					break;
			case "color_5": 
					body.attr("data-sidebarbg", "color_5");
					break;
			case "color_6": 
					body.attr("data-sidebarbg", "color_6");
					break;
			case "color_7": 
					body.attr("data-sidebarbg", "color_7");
					break;
			case "color_8": 
					body.attr("data-sidebarbg", "color_8");
					break;
			case "color_9": 
					body.attr("data-sidebarbg", "color_9");
					break;
			case "color_10": 
					body.attr("data-sidebarbg", "color_10");
					break;
	case "color_11": 
					body.attr("data-sidebarbg", "color_11");
					break;
	case "color_12": 
					body.attr("data-sidebarbg", "color_12");
					break;
	case "color_13": 
					body.attr("data-sidebarbg", "color_13");
					break;
	case "color_14": 
					body.attr("data-sidebarbg", "color_14");
					break;
	case "color_15": 
					body.attr("data-sidebarbg", "color_15");
					break;			
			case "image_1": 
					body.attr("data-sidebarbg", "image_1");
					break;
			case "image_2": 
					body.attr("data-sidebarbg", "image_2");
					break;
			case "image_3": 
					body.attr("data-sidebarbg", "image_3");
					break;
			default:
					body.attr("data-sidebarbg", "color_1");
	}
}
dzSettings.prototype.manageSidebarPosition = function() {
	switch(this.sidebarPosition) {
			case "fixed": 
					this.sidebarStyle === "overlay" && this.layout === "vertical" || this.sidebarStyle === "modern" ? body.attr("data-sidebar-position", "static") : body.attr("data-sidebar-position", "fixed");
					break;
			case "static": 
					body.attr("data-sidebar-position", "static");
					break;
			default: 
					body.attr("data-sidebar-position", "static");       
	}
}
dzSettings.prototype.manageHeaderPosition = function() {
	switch(this.headerPosition) {
			case "fixed": 
					body.attr("data-header-position", "fixed");
					break;
			case "static": 
					body.attr("data-header-position", "static");
					break;
			default: 
					body.attr("data-header-position", "static");       
	}
}
dzSettings.prototype.manageContainerLayout = function() {
	switch(this.containerLayout) {
			case "boxed":
					if(this.layout === "vertical" && this.sidebarStyle === "full") {
							body.attr("data-sidebar-style", "overlay");
					}
					body.attr("data-container", "boxed");
					break;
			case "wide":
					body.attr("data-container", "wide");
					break;
			case "wide-boxed": 
					body.attr("data-container", "wide-boxed");
					break;
			default:
					body.attr("data-container", "wide");
	}
}
dzSettings.prototype.manageRtlLayout = function() {

//alert(this.direction);
	switch(this.direction) {
			case "rtl":
					html.attr("dir", "rtl");
					html.addClass('rtl');
					body.attr("direction", "rtl");
					break;
			case "ltr": 
					html.attr("dir", "ltr");
					html.removeClass('rtl');
					body.attr("direction", "ltr");
					break;
			default: 
					html.attr("dir", "ltr");
					body.attr("direction", "ltr");
	}


}
dzSettings.prototype.manageResponsiveSidebar = function() {
	const innerWidth = $(window).innerWidth();
	if(innerWidth < 1024) {
			body.attr("data-layout", "vertical");
			body.attr("data-container", "wide");
	}

	if(innerWidth > 767 && innerWidth < 1024) {
			body.attr("data-sidebar-style", "mini");
	}

	if(innerWidth < 768) {
			body.attr("data-sidebar-style", "overlay");
	}
}
dzSettings.prototype.managePrimaryColor = function() {
switch(this.primary) {
			case "color_1": 
					body.attr("data-primary", "color_1");
		 break;
			case "color_2": 
					body.attr("data-primary", "color_2");
					break;
	case "color_3": 
					body.attr("data-primary", "color_3");
					break;
	case "color_4": 
					body.attr("data-primary", "color_4");
					break;
	case "color_5": 
					body.attr("data-primary", "color_5");
					break;
	case "color_6": 
					body.attr("data-primary", "color_6");
					break;	
	case "color_7": 
					body.attr("data-primary", "color_7");
					break;
	case "color_8": 
					body.attr("data-primary", "color_8");
					break;
	case "color_9": 
					body.attr("data-primary", "color_9");
					break;
	case "color_10": 
					body.attr("data-primary", "color_10");
					break;
	case "color_11": 
					body.attr("data-primary", "color_11");
					break;
	case "color_12": 
					body.attr("data-primary", "color_12");
					break;
	case "color_13": 
					body.attr("data-primary", "color_13");
					break;
	case "color_14": 
					body.attr("data-primary", "color_14");
					break;
	case "color_15": 
					body.attr("data-primary", "color_15");
					break;	
		
			default:
					body.attr("data-primary", "color_1");
	}
}

dzSettings.prototype.manageSecondaryColor = function() {
switch(this.secondary) {
			case "color_1": 
					body.attr("data-secondary", "color_1");
		 break;
			case "color_2": 
					body.attr("data-secondary", "color_2");
					break;
	case "color_3": 
					body.attr("data-secondary", "color_3");
					break;
	case "color_4": 
					body.attr("data-secondary", "color_4");
					break;
	case "color_5": 
					body.attr("data-secondary", "color_5");
					break;
	case "color_6": 
					body.attr("data-secondary", "color_6");
					break;	
	case "color_7": 
					body.attr("data-secondary", "color_7");
					break;
	case "color_8": 
					body.attr("data-secondary", "color_8");
					break;
	case "color_9": 
					body.attr("data-secondary", "color_9");
					break;
	case "color_10": 
					body.attr("data-secondary", "color_10");
					break;
	case "color_11": 
					body.attr("data-secondary", "color_11");
					break;
	case "color_12": 
					body.attr("data-secondary", "color_12");
					break;
	case "color_13": 
					body.attr("data-secondary", "color_13");
					break;
	case "color_14": 
					body.attr("data-secondary", "color_14");
					break;
	case "color_15": 
					body.attr("data-secondary", "color_15");
					break;	
		
			default:
					body.attr("data-secondary", "color_1");
	}
}

var W3Crm = function(){
	"use strict"
	/* Search Bar ============ */
	var screenWidth = $( window ).width();
	var screenHeight = $( window ).height();
	
	
	var handleSelectPicker = function(){
		if(jQuery('.default-select,.table-responsive select').length > 0 ){
			jQuery('.default-select,.table-responsive select').selectpicker();
		}
	}
	var handlePreloader = function(){
		setTimeout(function() {
			jQuery('#preloader').remove();
			$('#main-wrapper').addClass('show');
		},800);	
		
	}

    var handleMetisMenu = function() {
		if(jQuery('#menu').length > 0 ){
			$("#menu").metisMenu();
		}
		jQuery('.metismenu > .mm-active ').each(function(){
			if(!jQuery(this).children('ul').length > 0)
			{
				jQuery(this).addClass('active-no-child');
			}
		});
	}
   
    var handleAllChecked = function() {
		$("#checkAll").on('change',function() {
			$("td input, .email-list .custom-checkbox input").prop('checked', $(this).prop("checked"));
		});
		
		$(".checkAllInput").on('change',function() {
			jQuery(this).parents('.ItemsCheckboxSec').find('input[type="checkbox"]').prop( "checked", $(this).prop("checked") );		
		});
	}

	var handleNavigation = function() {
		$(".nav-control").on('click', function() {

			$('#main-wrapper').toggleClass("menu-toggle");

			$(".hamburger").toggleClass("is-active");
		});
	}
  
	var handleCurrentActive = function() {
		for (var nk = window.location,
			o = $("ul#menu a").filter(function() {
				
				return this.href == nk;
				
			})
			.addClass("mm-active")
			.parent()
			.addClass("mm-active");;) 
		{
			
			if (!o.is("li")) break;
			
			o = o.parent()
				.addClass("mm-show")
				.parent()
				.addClass("mm-active");
		}
	}

	var handleMiniSidebar = function() {
		$("ul#menu>li").on('click', function() {
			const sidebarStyle = $('body').attr('data-sidebar-style');
			if (sidebarStyle === 'mini') {
				console.log($(this).find('ul'))
				$(this).find('ul').stop()
			}
		})
	}
   
	var handleMinHeight = function() {
		var win_h = window.outerHeight;
		var win_h = window.outerHeight;
		if (win_h > 0 ? win_h : screen.height) {
			$(".content-body").css("min-height", (win_h + 0) + "px");
		};
	}
    
	var handleDataAction = function() {
		$('a[data-action="collapse"]').on("click", function(i) {
			i.preventDefault(),
				$(this).closest(".card").find('[data-action="collapse"] i').toggleClass("mdi-arrow-down mdi-arrow-up"),
				$(this).closest(".card").children(".card-body").collapse("toggle");
		});

		$('a[data-action="expand"]').on("click", function(i) {
			i.preventDefault(),
				$(this).closest(".card").find('[data-action="expand"] i').toggleClass("icon-size-actual icon-size-fullscreen"),
				$(this).closest(".card").toggleClass("card-fullscreen");
		});



		$('[data-action="close"]').on("click", function() {
			$(this).closest(".card").removeClass().slideUp("fast");
		});

		$('[data-action="reload"]').on("click", function() {
			var e = $(this);
			e.parents(".card").addClass("card-load"),
				e.parents(".card").append('<div class="card-loader"><i class=" ti-reload rotate-refresh"></div>'),
				setTimeout(function() {
					e.parents(".card").children(".card-loader").remove(),
						e.parents(".card").removeClass("card-load")
				}, 2000)
		});
	}

    var handleHeaderHight = function() {
		const headerHight = $('.header').innerHeight();
		$(window).scroll(function() {
			if ($('body').attr('data-layout') === "horizontal" && $('body').attr('data-header-position') === "static" && $('body').attr('data-sidebar-position') === "fixed")
				$(this.window).scrollTop() >= headerHight ? $('.deznav').addClass('fixed') : $('.deznav').removeClass('fixed')
		});
	}
	
	var handleMenuTabs = function() {
		if(screenWidth <= 991 ){
			jQuery('.menu-tabs .nav-link').on('click',function(){
				if(jQuery(this).hasClass('open'))
				{
					jQuery(this).removeClass('open');
					jQuery('.fixed-content-box').removeClass('active');
					jQuery('.hamburger').show();
				}else{
					jQuery('.menu-tabs .nav-link').removeClass('open');
					jQuery(this).addClass('open');
					jQuery('.fixed-content-box').addClass('active');
					jQuery('.hamburger').hide();
				}
				//jQuery('.fixed-content-box').toggleClass('active');
			});
			jQuery('.close-fixed-content').on('click',function(){
				jQuery('.fixed-content-box').removeClass('active');
				jQuery('.hamburger').removeClass('is-active');
				jQuery('#main-wrapper').removeClass('menu-toggle');
				jQuery('.hamburger').show();
			});
		}
	}
	/* Header Fixed ============ */
	var headerFix = function(){
		'use strict';
		/* Main navigation fixed on top  when scroll down function custom */		
		jQuery(window).on('scroll', function () {
			
			if(jQuery('.header').length > 0){
				var menu = jQuery('.header');
				$(window).scroll(function(){
				  var sticky = $('.header'),
					  scroll = $(window).scrollTop();

				  if (scroll >= 100){ sticky.addClass('is-fixed');
									}else {sticky.removeClass('is-fixed');}
				});				
			}
			
		});
		/* Main navigation fixed on top  when scroll down function custom end*/
	}
	
	var handleChatbox = function() {
		jQuery('.bell-link').on('click',function(){
			jQuery('.chatbox').addClass('active');
		});
		jQuery('.chatbox-close').on('click',function(){
			jQuery('.chatbox').removeClass('active');
		});
	}
	
	var handlePerfectScrollbar = function() {
		if(jQuery('.deznav-scroll').length > 0)
		{
			//const qs = new PerfectScrollbar('.deznav-scroll');
			const qs = new PerfectScrollbar('.deznav-scroll');
			
			qs.isRtl = false;
		}
	}

	var handleBtnNumber = function() {
		$('.btn-number').on('click', function(e) {
			e.preventDefault();

			fieldName = $(this).attr('data-field');
			type = $(this).attr('data-type');
			var input = $("input[name='" + fieldName + "']");
			var currentVal = parseInt(input.val());
			if (!isNaN(currentVal)) {
				if (type == 'minus')
					input.val(currentVal - 1);
				else if (type == 'plus')
					input.val(currentVal + 1);
			} else {
				input.val(0);
			}
		});
	}
	
	var handleDzChatUser = function() {
		jQuery('.dz-chat-user-box .dz-chat-user').on('click',function(){
			jQuery('.dz-chat-user-box').addClass('d-none');
			jQuery('.dz-chat-history-box').removeClass('d-none');
            //$(".chatbox .msg_card_body").height(vHeightArea());
            //$(".chatbox .msg_card_body").css('height',vHeightArea());
		}); 
		
		jQuery('.dz-chat-history-back').on('click',function(){
			jQuery('.dz-chat-user-box').removeClass('d-none');
			jQuery('.dz-chat-history-box').addClass('d-none');
		}); 
		
		jQuery('.dz-fullscreen').on('click',function(){
			jQuery('.dz-fullscreen').toggleClass('active');
		});
        
        /* var vHeight = function(){ */
            
        /* } */
        
        
	}
	
	
	var handleDzFullScreen = function() {
		jQuery('.dz-fullscreen').on('click',function(e){
			if(document.fullscreenElement||document.webkitFullscreenElement||document.mozFullScreenElement||document.msFullscreenElement) { 
				/* Enter fullscreen */
				if(document.exitFullscreen) {
					document.exitFullscreen();
				} else if(document.msExitFullscreen) {
					document.msExitFullscreen(); /* IE/Edge */
				} else if(document.mozCancelFullScreen) {
					document.mozCancelFullScreen(); /* Firefox */
				} else if(document.webkitExitFullscreen) {
					document.webkitExitFullscreen(); /* Chrome, Safari & Opera */
				}
			} 
			else { /* exit fullscreen */
				if(document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen();
				} else if(document.documentElement.webkitRequestFullscreen) {
					document.documentElement.webkitRequestFullscreen();
				} else if(document.documentElement.mozRequestFullScreen) {
					document.documentElement.mozRequestFullScreen();
				} else if(document.documentElement.msRequestFullscreen) {
					document.documentElement.msRequestFullscreen();
				}
			}		
		});
	}
	
	var handleshowPass = function(){
		jQuery('.show-pass').on('click',function(){
			jQuery(this).toggleClass('active');
			if(jQuery('#dz-password').attr('type') == 'password'){
				jQuery('#dz-password').attr('type','text');
			}else if(jQuery('#dz-password').attr('type') == 'text'){
				jQuery('#dz-password').attr('type','password');
			}
		});
	}

	
	var heartBlast = function (){
		$(".heart").on("click", function() {
			$(this).toggleClass("heart-blast");
		});
	}
	
	var handleDzLoadMore = function() {
		$(".dz-load-more").on('click', function(e)
		{
			e.preventDefault();	//STOP default action
			$(this).append(' <i class="fas fa-sync"></i>');
			
			var dzLoadMoreUrl = $(this).attr('rel');
			var dzLoadMoreId = $(this).attr('id');
			
			$.ajax({
				method: "POST",
				url: dzLoadMoreUrl,
				dataType: 'html',
				success: function(data) {
					$( "#"+dzLoadMoreId+"Content").append(data);
					$('.dz-load-more i').remove();
				}
			})
		});
	}
	
	var handleLightgallery = function(){
		if(jQuery('#lightgallery').length > 0){
			$('#lightgallery').lightGallery({
				loop:true,
				thumbnail:true,
				exThumbImage: 'data-exthumbimage'
			});
		}
	}
	var handleCustomFileInput = function() {
		$(".custom-file-input").on("change", function() {
			var fileName = $(this).val().split("\\").pop();
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
	}
    
  	var vHeight = function(){
        var ch = $(window).height() - 206;
        $(".chatbox .msg_card_body").css('height',ch);
    }
	
	var handleDatetimepicker = function(){
		if(jQuery("#datetimepicker1").length>0) {
			$('#datetimepicker1').datetimepicker({
				inline: true,
			});
		}
	}
	
	var handleCkEditor = function(){
		if(jQuery("#ckeditor").length>0) {
			ClassicEditor
			.create( document.querySelector( '#ckeditor' ), {
				// toolbar: [ 'heading', '|', 'bold', 'italic', 'link' ]
			} )
			.then( editor => {
				window.editor = editor;
			} )
			.catch( err => {
				console.error( err.stack );
			} );
		}
	}
	
	var handleMenuPosition = function(){
		
		if(screenWidth > 1024){
			$(".metismenu  li").unbind().each(function (e) {
				if ($('ul', this).length > 0) {
					var elm = $('ul:first', this).css('display','block');
					var off = elm.offset();
					var l = off.left;
					var w = elm.width();
					var elm = $('ul:first', this).removeAttr('style');
					var docH = $("body").height();
					var docW = $("body").width();
					
					if(jQuery('html').hasClass('rtl')){
						var isEntirelyVisible = (l + w <= docW);	
					}else{
						var isEntirelyVisible = (l > 0)?true:false;	
					}
						
					if (!isEntirelyVisible) {
						$(this).find('ul:first').addClass('left');
					} else {
						$(this).find('ul:first').removeClass('left');
					}
				}
			});
		}
	}	
	
	var handleChartSidebar = function(){
		$('.chat-rightarea-btn').on('click',function(){
			$(this).toggleClass('active');
			$('.chat-right-area').toggleClass('active');
		})
		$('.chat-hamburger').on('click',function(){
			$('.chat-left-area').toggleClass('active');
		})
	}
	
	var MagnificPopup = function(){
		'use strict';	
		if($(".popup-youtube, .popup-vimeo, .popup-gmaps").length > 0 ) {
			/* magnificPopup for paly video function end*/
			$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
				disableOn: 700,
				type: 'iframe',
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,

				fixedContentPos: false
			});
		}
	}
	
	var handleDraggableCard = function() {
		var dzCardDraggable = function () {
		 return {
		  //main function to initiate the module
		  init: function () {
		   var containers = document.querySelectorAll('.draggable-zone');

		   if (containers.length === 0) {
			return false;
		   }

		   var swappable = new Sortable.default(containers, {
			draggable: '.draggable',
			handle: '.draggable.draggable-handle',
			mirror: {
			 appendTo: 'body',
			 constrainDimensions: true
			}
			
		   });
		   swappable.on('drag:stop', () => {
				setTimeout(function(){
					setBoxCount();
				}, 200);
				
			})
		  }
		 };
		}();

		jQuery(document).ready(function () {
		 dzCardDraggable.init();
		});
		
		
		function setBoxCount(){
			var cardCount = 0;
			jQuery('.dropzoneContainer').each(function(){
				cardCount = jQuery(this).find('.draggable-handle').length;
				jQuery(this).find('.totalCount').html(cardCount);
			});
		}
	}
	
	/* Masonry Box ============ */
	var masonryBox = function(){
		'use strict';
		/* masonry by  = bootstrap-select.min.js */
		if(jQuery('#masonry, .masonry').length > 0){
			
			setTimeout(function(){
				jQuery('.filters li').removeClass('active');
				jQuery('.filters li:first').addClass('active');
				var self = jQuery("#masonry, .masonry"); 
				var filterValue = $('.filters li:first').attr("data-filter");
				
				function handleIsotope(filterValue){
					self.isotope({ 
						filter: filterValue
					});
				}
				
				if(jQuery('.filters').length){
					jQuery(".filters li:first").addClass('active');
					
					filterValue = $('.filters li:first').attr("data-filter");
					
					handleIsotope(filterValue);
					
					jQuery(".filters").on("click", "li", function() {
						jQuery('.filters li').removeClass('active');
						jQuery(this).addClass('active');
						
						filterValue = $(this).attr("data-filter");
						handleIsotope(filterValue);
					});
				}
			
			}, 500);
			
		}
		/* masonry by = bootstrap-select.min.js end */
	}
	
	var handleConverterTheme = function(){
		if($('.btc-converts').length > 0){
			setTimeout(()=> {
				if($('body').attr('data-theme-version') === "dark"){
					$('.btc-converts').attr('dark-mode', true);
				} 
			},1000);
			$('#theme_version').on('change',function(){
				if($('body').attr('data-theme-version') === "dark"){
					$('.btc-converts').attr('dark-mode', true);
				} else{
					$('.btc-converts').attr('dark-mode', false);
				}
			});
		}
	}
	/* Handle Page On Scroll ============ */
	/* Handle Page On Scroll ============ */
	var handlePageOnScroll = function(event){
		
		'use strict';
		var headerHeight = parseInt($('.header').css('height'), 10);
		
		$('.navbar-nav .scroll').on('click', function(event) 
		{
			event.preventDefault();

			jQuery('.navbar-nav .scroll').parent().removeClass('active');
			jQuery(this).parent().addClass('active');
			
			if (this.hash !== "") {
				var hash = this.hash;	
				var seactionPosition = parseInt($(hash).offset().top, 10);
				var headerHeight =   parseInt($('.header').css('height'), 10);
				
				var scrollTopPosition = seactionPosition - headerHeight;
				$('html, body').animate({
					scrollTop: scrollTopPosition
				}, 800, function(){
					
				});
			}   
		});
		
		pageOnScroll();
	}

	/* Page On Scroll ============ */
	var pageOnScroll = function(event){
		
		if(jQuery('.navbar-nav').length > 0){
			
			var headerHeight = parseInt(jQuery('.header').height(), 10);
			
			jQuery(document).on("scroll", function(){
				
				var scrollPos = jQuery(this).scrollTop();
				jQuery('.navbar-nav .scroll').each(function () {
					var elementLink = jQuery(this);
					
					//console.log(this.hash);
					//console.log(jQuery(this.hash).offset());
					
					var refElement = jQuery(elementLink.attr("href"));
					
					if(jQuery(this.hash).offset() != undefined){
						var seactionPosition = parseInt(jQuery(this.hash).offset().top, 10);
					}else{
						var seactionPosition = 0;
					}
					var scrollTopPosition = (seactionPosition - headerHeight);

					if (scrollTopPosition <= scrollPos){
						elementLink.parent().addClass("active");
						elementLink.parent().siblings().removeClass("active");
					}
				});
				
			});
		}
	} 
	
	var handleImageSelect = function(){

		const $_SELECT_PICKER = $('.image-select');
		$_SELECT_PICKER.find('option').each((idx, elem) => {
			const $OPTION = $(elem);
			const IMAGE_URL = $OPTION.attr('data-thumbnail');
			if (IMAGE_URL) {
				$OPTION.attr('data-content', "<img src='%i'/> %s".replace(/%i/, IMAGE_URL).replace(/%s/, $OPTION.text()))
			}
		});
		$_SELECT_PICKER.selectpicker();
	}
	var tagify = function(){
		if(jQuery('input[name=tagify]').length > 0){
		
		// The DOM element you wish to replace with Tagify
			var input = document.querySelector('input[name=tagify]');

			// initialize Tagify on the above input node reference
			new Tagify(input);
			
		}
	}
  
  
	/* Function ============ */
	return {
		init:function(){
			handleMetisMenu();
			handleAllChecked();
			handleNavigation();
			handleCurrentActive();
			handleMiniSidebar();
			handleMinHeight();
			handleDataAction();
			handleHeaderHight();
			handleMenuTabs();
			handleChatbox();
			//handlePerfectScrollbar();
			handleBtnNumber();
			handleDzChatUser();
			handleDzFullScreen();
			handleshowPass();
			heartBlast();
			handleDzLoadMore();
			handleLightgallery();
			handleCustomFileInput();
			vHeight();
			handleDatetimepicker();
			handleCkEditor();
			headerFix();
			handleChartSidebar();
			MagnificPopup();
			handleDraggableCard();
			handleConverterTheme();
			//handleImageSelect();
			handleSelectPicker();
			handlePageOnScroll();
			tagify();
			// masonryBox();
		},

		
		load:function(){
			handlePreloader();
			//handleNiceSelect();
			masonryBox();
		},
		
		resize:function(){
			vHeight();
			
		},
		
		handleMenuPosition:function(){
			
			handleMenuPosition();
		},
	}
	
}();

/* Document.ready Start */	
// jQuery(document).ready(function() {
	// $('[data-bs-toggle="popover"]').popover();
	W3Crm.init();
	W3Crm.load();
	setTimeout(function(){
			W3Crm.handleMenuPosition();
	}, 1000);
	
	jQuery(window).on('resize',function () {
		W3Crm.resize();
		setTimeout(function(){
				W3Crm.handleMenuPosition();
		}, 1000);
	});
// });
/* Document.ready END */

/* Window Load START */
// jQuery(window).on('load',function () {
	// 'use strict'; 
	
// });
/*  Window Load END */
/* Window Resize START */
/*  Window Resize END */

var dzSettingsOptions = {};

function getUrlParams(dParam) 
	{
		var dPageURL = window.location.search.substring(1),
			dURLVariables = dPageURL.split('&'),
			dParameterName,
			i;

		for (i = 0; i < dURLVariables.length; i++) {
			dParameterName = dURLVariables[i].split('=');

			if (dParameterName[0] === dParam) {
				return dParameterName[1] === undefined ? true : decodeURIComponent(dParameterName[1]);
			}
		}
}

(function($) {
	
	dzSettingsOptions = {
		typography: "poppins",
		version: "light",
		layout: "vertical",
		primary: "color_1",
		headerBg: "color_4",
		navheaderBg: "color_4",
		sidebarBg: "color_1",
		sidebarStyle: "full",
		sidebarPosition: "fixed",
		headerPosition: "fixed",
		containerLayout: "full",
	};
	
	new dzSettings(dzSettingsOptions); 

	jQuery(window).on('resize',function(){
        /*Check container layout on resize */
		///alert(dzSettingsOptions.primary);
        dzSettingsOptions.containerLayout = $('#container_layout').val();
        /*Check container layout on resize END */
        
		new dzSettings(dzSettingsOptions); 
	});
	
})(jQuery);
})