// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../w3crm/css/style.css'
import '../w3crm/vendor/animate/animate.min.css'
import '../w3crm/vendor/aos/css/aos.min.css'
import "../w3crm/icons/simple-line-icons/css/simple-line-icons.css";

require("@popperjs/core")
window.$ = jQuery
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
import "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import "metismenu"
import "metismenu/dist/metisMenu.css"
import "datatables"
// require("datatables")
import "datatables/media/css/jquery.dataTables.min.css"
// import "font-awesome/css/font-awesome.min.css"
import toastr from 'toastr';
import "toastr/build/toastr.min.css";
window.toastr = toastr;
// import "bootstrap-select/dist/css/bootstrap-select.css"
require("../w3crm/js/custom.js")
import html2canvas from 'html2canvas';
window.html2canvas = html2canvas

// $('#family_table').DataTable()

// toastr.success("This Is Success Message", "Top Right", {
//   timeOut: 500000000,
//   closeButton: !0,
//   debug: !1,
//   newestOnTop: !0,
//   progressBar: !0,
//   positionClass: "toast-top-right",
//   preventDuplicates: !0,
//   onclick: null,
//   showDuration: "300",
//   hideDuration: "1000",
//   extendedTimeOut: "1000",
//   showEasing: "swing",
//   hideEasing: "linear",
//   showMethod: "fadeIn",
//   hideMethod: "fadeOut",
//   tapToDismiss: !1
// })